import groupBy from "lodash.groupby";

import Job from "./Job";

export default class Alumni {
  constructor(data = {}) {
    this.id = null;
    this.allowInfoSending = false;
    this.birthDate = "";
    this.email = "";
    this.firstName = "";
    this.fullName = "";
    this.isCaMember = false;
    this.isContributor = false;
    this.isProfessionalPublic = false;
    this.isPublic = false;
    this.lastName = "";
    this.locationAdress = "";
    this.locationCity = "";
    this.locationCountry = "";
    this.locationPostcode = null;
    this.pathway = "";
    this.phoneNumber = "";
    this.promo = "";
    this.publicUsername = "";
    this.tnfEmail = "";
    this.user = "";
    this.linkedinUrl = "";
    this.hasConsentedToNewsletter = false;
    this.skills = [];
    this.profilePicture = null;
    this.setData(data);
  }
  setData(data) {
    for (let [key, value] of Object.entries(data)) {
      this[key] = value;
    }

    this.currentJobs = [];
    for (let index = 0; index < data.currentJobs.length; index++) {
      this.currentJobs.push(new Job(data.currentJobs[index]));
    }
  }
  getCrudData() {
    return {
      birthDate: this.birthDate,
      locationCity: this.locationCity,
      locationCountry: this.locationCountry,
      phoneNumber: this.phoneNumber,
      linkedinUrl: this.linkedinUrl,
      hasConsentedToNewsletter: this.hasConsentedToNewsletter,
      skills: this.skills.map((skill) => skill.id),
    };
  }
  getSkillsByCategory() {
    return groupBy(this.skills, "categoryName");
  }
}
