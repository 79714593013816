<template>
  <v-main>
    <keep-alive v-if="isAppLoaded" :include="['AlumniList', 'Association']">
      <router-view />
    </keep-alive>
    <v-overlay v-else>
      <v-progress-circular size="64" indeterminate />
    </v-overlay>
  </v-main>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppView",
  computed: {
    ...mapState(["isAppLoaded"]),
  },
};
</script>
