<template>
  <v-app-bar app color="white" outlined clipped-left>
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      @click.stop="$emit('drawer:toogle')"
    />
    <router-link :to="{ name: 'app-home' }">
      <v-img src="@/assets/images/logo.png" contain height="36px" />
    </router-link>
    <v-spacer />
    <router-link :to="{ name: 'profile' }">
      <v-avatar :size="48">
        <v-img
          :src="
            alumni
              ? alumni.profilePicture
                ? alumni.profilePicture.original.thumbnailUrl
                : require(`@/assets/images/alumni/alumni-${getAlumniPictureNumber(
                    alumni.id
                  )}.png`)
              : null
          "
          cover
        />
      </v-avatar>
    </router-link>
  </v-app-bar>
</template>


<script>
import { mapGetters } from "vuex";

import { getAlumniPictureNumber } from "@/services/alumni";

export default {
  name: "NavigationBar",
  computed: {
    ...mapGetters({ alumni: "alumni/userAlumni" }),
  },
  methods: {
    getAlumniPictureNumber,
  },
};
</script>
