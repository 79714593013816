var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"app":"","color":"white","outlined":"","clipped-left":""}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('drawer:toogle')}}}):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'app-home' }}},[_c('v-img',{attrs:{"src":require("@/assets/images/logo.png"),"contain":"","height":"36px"}})],1),_c('v-spacer'),_c('router-link',{attrs:{"to":{ name: 'profile' }}},[_c('v-avatar',{attrs:{"size":48}},[_c('v-img',{attrs:{"src":_vm.alumni
            ? _vm.alumni.profilePicture
              ? _vm.alumni.profilePicture.original.thumbnailUrl
              : require(`@/assets/images/alumni/alumni-${_vm.getAlumniPictureNumber(
                  _vm.alumni.id
                )}.png`)
            : null,"cover":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }