export default {
  WARNING_CODE: {
    SIGN_OUT: "SIGN_OUT",
  },

  ERROR_CODE: {
    INVALID_RESET_PASSWORD_LINK: "INVALID_RESET_PASSWORD_LINK",
    UNKNOWN_ERROR: "UNKNOWN_ERROR",
    JOB_DELETION: "JOB_DELETION",
  },

  SUCCESS_CODE: {
    ACTIVATION: "ACTIVATION",
    LOGGED_IN: "LOGGED_IN",
    REGISTRATION: "REGISTRATION",
    RESET_PASSWORD: "RESET_PASSWORD",
    ALUMNI_EDITION: "ALUMNI_EDITION",
    JOB_CREATION: "JOB_CREATION",
    JOB_EDITION: "JOB_EDITION",
    JOB_DELETION: "JOB_DELETION",
  },
};
