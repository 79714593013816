<template>
  <v-card :to="to" :href="href" :target="target" :disabled="disabled">
    <v-card-title :class="['flex-nowrap', titleClass]">
      <slot name="header">
        <v-icon left :color="iconColor">{{ icon }}</v-icon>
        {{ title }}
        <v-spacer />
        <slot name="append:header" />
      </slot>
    </v-card-title>
    <v-card-subtitle v-if="subTitle">
      {{ subTitle }}
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <slot></slot>
    </v-card-text>
    <slot v-if="!hideFooter" name="footer">
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="!hideCancelFooterButton" text @click="cancel">
          {{ cancelText || $t("action.cancel") }}</v-btn
        >
        <v-btn color="secondary" outlined @click="submit">
          {{ submitText }}
        </v-btn>
      </v-card-actions>
    </slot>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
      default: "",
    },
    titleClass: {
      type: String,
      required: false,
      default: "primary--text",
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    iconColor: {
      type: String,
      required: false,
      default: "primary",
    },
    to: {
      type: Object,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: "",
    },
    target: {
      type: String,
      required: false,
      default: "",
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCancelFooterButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancelText: {
      type: String,
      required: false,
      default: "",
    },
    submitText: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit("click:cancel");
    },
    submit() {
      this.$emit("click:submit");
    },
  },
};
</script>

<style>
</style>
