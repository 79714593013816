import intranetClient from "../api/intranet";
import Error from "../models/Error";

export const login = async (data) => {
  try {
    const response = await intranetClient.post("/auth/jwt/create/", data);
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};

export const register = async (data) => {
  try {
    const response = await intranetClient.post("/auth/users/", data);
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};

export const getAccountData = async () => {
  try {
    const response = await intranetClient.get("/auth/users/me/");
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};
export const activateAccount = async (data) => {
  try {
    const response = await intranetClient.post("/auth/users/activation/", data);
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};
export const askResetAccountPassword = async (data) => {
  try {
    const response = await intranetClient.post(
      "/auth/users/reset_password/",
      data
    );
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};
export const resetAccountPassword = async (data) => {
  try {
    const response = await intranetClient.post(
      "/auth/users/reset_password_confirm/",
      {
        new_password: data.password,
        re_new_password: data.password,
        token: data.token,
        uid: data.uid,
      }
    );
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};
