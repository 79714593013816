export default [
  {
    path: "alumni/",
    name: "alumni-list",
    component: () =>
      import(/* webpackChunkName: "AlumniList" */ "../views/AlumniList.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "alumni/:id/",
    name: "alumni-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "AlumniDetail" */ "../views/AlumniDetail.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "alumni/subscription",
    name: "alumni-subscription",
    component: () =>
      import(
        /* webpackChunkName: "AlumniSubscription" */ "../views/AlumniSubscription.vue"
      ),
  },
];
