import qs from "query-string";
import seedrandom from "seedrandom";
import snakecaseKeys from "snakecase-keys";

import intranetClient from "@/api/intranet";
import Error from "@/models/Error";

export const getAlumniPictureNumber = (id) => {
  const seed = seedrandom(id);
  if (seed() < 0.33) return 1;
  if (seed() < 0.66) return 2;
  return 3;
};

export const getAccountAlumni = async () => {
  try {
    const response = await intranetClient.get("/students/me/");
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};

export const editAlumni = async (alumni) => {
  const payload = alumni.getCrudData();
  try {
    const response = await intranetClient.patch(
      `/students/${alumni.id}/`,
      payload
    );
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};

export const getAlumni = async (id) => {
  try {
    const response = await intranetClient.get(`/students/${id}`);
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};

export const getAlumnis = async (query = {}) => {
  query = qs.stringify(snakecaseKeys(query), { skipEmptyString: true });
  try {
    const response = await intranetClient.get(`/students?${query}`);
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};

export const getAlumniSkills = async () => {
  try {
    const response = await intranetClient.get("/search/skills/");
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};

export const createJob = async (job) => {
  try {
    const response = await intranetClient.post(`/jobs/`, job);
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};
export const editJob = async (job) => {
  try {
    const response = await intranetClient.put(`/jobs/${job.id}/`, job);
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};
export const deleteJob = async (id) => {
  try {
    const response = await intranetClient.delete(`/jobs/${id}/`);
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};

export const addAlumniPicture = async (picture) => {
  let formData = new FormData();
  formData.append("original", picture);
  try {
    const response = await intranetClient.post("/profile-pictures/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};
