import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import router from "../router";

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../utils/localStorage";

const intranetClient = axios.create({
  baseURL: process.env.VUE_APP_INTRANET_API_URL,
  timeout: 10000,
});
intranetClient.interceptors.request.use(
  function (request) {
    if (
      request.data &&
      request.headers["Content-Type"] !== "multipart/form-data"
    ) {
      request.data = snakecaseKeys(request.data, { deep: true });
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

intranetClient.interceptors.response.use(
  (response) => {
    response.data = camelcaseKeys(response.data, { deep: true });
    return Promise.resolve(response);
  },
  async (e) => {
    const statusCode = e.response.status;
    if (
      statusCode === 401 &&
      e.response.config.url !== "/auth/jwt/refresh/" &&
      ["token_not_valid", "Token is invalid or expired"].includes(
        e.response.data?.errors[0]?.code
      )
    ) {
      const originalRequest = e.config;
      try {
        const { data } = await intranetClient.post("/auth/jwt/refresh/", {
          refresh: getLocalStorageItem("refresh-token"),
        });
        setBearerToken(data.access);
        setLocalStorageItem("access-token", data.access);
        originalRequest.headers["Authorization"] = `Bearer ${data.access}`;
        return await intranetClient.request(originalRequest);
      } catch (error) {
        router.push({ name: "signout" });
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(e);
    }
  }
);

export const setBearerToken = (accessToken) => {
  intranetClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${accessToken}`;
};

export const deleteBearerToken = () => {
  delete intranetClient.defaults.headers.common.Authorization;
};

export const isFormError = (errorData) => {
  if (typeof error == "object") {
    return errorData.errors.some((error) => error.field);
  }
  return false;
};

export default intranetClient;
