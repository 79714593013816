import Vue from "vue";
import groupBy from "lodash.groupby";

import Alumni from "@/models/Alumni";

import {
  getAccountAlumni,
  editAlumni,
  getAlumniSkills,
} from "@/services/alumni";
export default {
  namespaced: true,
  state: {
    userAlumni: null,
    alumniSkills: [],
  },
  getters: {
    userAlumni: (state) => state.userAlumni,
    alumniSkills: (state) => state.skills,
    alumniSkillsByCategory: (state) => groupBy(state.skills, "categoryName"),
  },
  mutations: {
    SET_USER_ALUMNI(state, alumni) {
      state.userAlumni = new Alumni(alumni);
    },
    SET_ALUMNI_SKILLS(state, skills) {
      state.skills = skills;
    },
  },
  actions: {
    async getAccountAlumni({ commit, dispatch }) {
      const [response] = await getAccountAlumni();
      if (response) {
        commit("SET_USER_ALUMNI", response.data);
      } else {
        dispatch("showNotification", null, { root: true });
      }
    },
    async editAccountAlumni({ commit }, alumni) {
      const payload = [null, null];
      const [response, error] = await editAlumni(alumni);
      if (response) {
        commit("SET_USER_ALUMNI", response.data);
        payload[0] = response;
        commit(
          "ADD_NOTIFICATION",
          {
            code: Vue.prototype.$code.SUCCESS_CODE.ALUMNI_EDITION,
            type: Vue.prototype.$notification.type.SUCCESS,
          },
          { root: true }
        );
      } else {
        payload[1] = error;
        if (error.hasGlobalMessage) {
          commit(
            "ADD_NOTIFICATION",
            {
              code: error.getGlobalMessage(),
              type: Vue.prototype.$notification.type.ERROR,
            },
            { root: true }
          );
        }
      }
      return Promise.resolve(payload);
    },
    async getSkills({ commit }) {
      const [response] = await getAlumniSkills();
      if (response) {
        commit("SET_ALUMNI_SKILLS", response.data);
      }
    },
    setUserAlumniProperties({ commit, state }, properties) {
      commit("SET_USER_ALUMNI", { ...state.userAlumni, ...properties });
    },
  },
};
