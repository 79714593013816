<template>
  <v-navigation-drawer v-model="drawer" app clipped>
    <v-list-item
      v-for="{ to, icon, name, title, exact, disabled } in routes"
      :key="name"
      :to="to"
      :exact="exact"
      :disabled="disabled"
      color="primary"
    >
      <template #default="{ active }">
        <v-list-item-icon>
          <v-icon :color="active ? 'primary' : ''">{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ title }}
        </v-list-item-title>
      </template>
    </v-list-item>
    <template #append>
      <div class="pa-2">
        <v-btn block depressed outlined :to="{ name: 'signout' }" color="error">
          {{ $t("action.sign-out") }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppNavigationDrawer",
  data() {
    return {
      drawer: true,
      routes: [
        {
          icon: "mdi-home ",
          title: this.$t("app.page.home.meta.title"),
          to: { name: "app-home" },
          exact: true,
        },
        {
          icon: "mdi-account-group",
          title: this.$t("app.page.alumni-list.meta.title"),
          to: { name: "alumni-list" },
          exact: true,
        },
        {
          icon: "mdi-bank",
          title: this.$t("app.page.association.meta.title"),
          to: { name: "about-association" },
        },
        {
          icon: "mdi-calendar",
          title: this.$t("app.page.events.meta.title"),
          to: { name: "events" },
        },
        {
          icon: "mdi-newspaper-variant-outline",
          title: this.$t("app.page.news.meta.title"),
          to: { name: "news" },
          disabled: true,
        },
      ],
    };
  },
  methods: {
    toogleDrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>
