import { camelCase } from "lodash";
export default class Error {
  constructor(error) {
    this.data = error.response.data;
    this.statusCode = error.response.status;
    this.hasGlobalMessage = this.data.errors.some(
      (error) => error.field === undefined
    );
  }
  getFieldMessages() {
    return (
      this.data.errors
        .filter((error) => error.field)
        .map((errorField) => transformErrorField(errorField)) || []
    );
  }
  getGlobalMessage() {
    const message =
      this.data.errors.filter((error) => error.field === undefined)[0] || null;
    return GLOBAL_MESSAGE[message.code.toUpperCase()] || null;
  }
}

const GLOBAL_MESSAGE = {
  NO_ACTIVE_ACCOUNT: "NO_ACTIVE_ACCOUNT",
  NO_STUDENT: "NO_STUDENT",
  STUDENT_NOT_AVAILABLE: "STUDENT_NOT_AVAILABLE",
};

const transformErrorField = (error) => {
  error.field = camelCase(error.field).replace("newPassword", "password");
  return error;
};
