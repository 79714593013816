export default class Job {
  constructor(data = {}) {
    this.id = null;
    this.function = "";
    this.description = "";
    this.company = "";
    this.contractType = "";
    this.isCurrentJob = false;
    this.dateStart = null;
    this.dateEnd = null;
    this.fixedPhoneNumber = "";
    this.mobilePhoneNumber = "";
    this.professionalEmail = "";
    this.locationAdress = "";
    this.locationCity = "";
    this.locationPostCode = "";
    this.locationCountry = "";
    this.setData(data);
  }
  setData(data) {
    for (let [key, value] of Object.entries(data)) {
      this[key] = value;
    }
  }
}
