import Vue from "vue";
import VueRouter from "vue-router";
import Vuelidate from "vuelidate";
import Meta from "vue-meta";

import i18n from "./i18n/i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import appSettings from "./vueUtilities/appSettings";
import dateUtils from "./vueUtilities/dateUtils";
import notification from "./vueUtilities/notification";
import code from "./vueUtilities/code";
import { setBearerToken } from "./api/intranet";
import { getLocalStorageItem } from "./utils/localStorage";
import "./plugins/global-registration";

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(Vuelidate);

Vue.prototype.$appSettings = appSettings;
Vue.prototype.$dateUtils = dateUtils;
Vue.prototype.$code = code;
Vue.prototype.$notification = notification;

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  i18n,
  created() {
    const accessToken = getLocalStorageItem("access-token");
    accessToken ? setBearerToken(accessToken) : null;
  },
  render: (h) => h(App),
}).$mount("#app");
