import Vue from "vue";

import { getEvents } from "@/services/events";

export default {
  namespaced: true,
  state: {
    events: [],
    loading: false,
  },
  getters: {
    eventsByDateDesc(state) {
      return state.events.slice().reverse();
    },
  },
  mutations: {
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async getEvents({ commit }) {
      commit("SET_LOADING", true);
      const [response] = await getEvents();
      if (response) {
        commit("SET_EVENTS", response.data);
      } else {
        commit(
          "ADD_NOTIFICATION",
          {
            code: "",
            type: Vue.prototype.$notification.type.ERROR,
          },
          { root: true }
        );
      }
      commit("SET_LOADING", false);
    },
  },
};
