<template>
  <v-snackbar
    v-show="notification"
    v-model="isSnackBarOpen"
    data-cy="notification"
    app
    bottom
    :color="color"
    close
    :timeout="-1"
  >
    {{ message }}
    <template #action="{ attrs }">
      <v-btn icon text v-bind="attrs" @click="removeNotification">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
const ERROR_COLOR = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

import { mapGetters, mapActions } from "vuex";
import debounce from "lodash.debounce";
import isEqual from "lodash.isequal";
export default {
  name: "AppNotificationAlert",
  data() {
    return {
      isSnackBarOpen: false,
    };
  },
  computed: {
    ...mapGetters(["notification"]),
    message() {
      return (
        this.$t(`${this.notification?.type}.${this.notification?.code}`) || null
      );
    },
    color() {
      return ERROR_COLOR[this.notification?.type] || null;
    },
  },
  watch: {
    notification(notification, old) {
      this.isSnackBarOpen = !!notification;
      if (!isEqual(notification, old)) {
        this.removeNotificationDebounced();
      }
    },
  },
  created() {
    this.removeNotificationDebounced = debounce(this.removeNotification, 5000);
  },
  beforeDestroy() {
    this.removeNotificationDebounced = null;
  },
  methods: {
    ...mapActions(["removeNotification"]),
  },
};
</script>
<i18n>
{
  "en": {
    "NO_ACTIVE_ACCOUNT" : "Invalid password"
  },
  "fr": {
    "NO_ACTIVE_ACCOUNT" : "Email ou mot de passe invalide"
  }
}
</i18n>
