import intranetClient from "@/api/intranet";

export const getEvents = async () => {
  try {
    const response = await intranetClient.get("/events");
    return [response, null];
  } catch (error) {
    return [null, new Error(error)];
  }
};
