import AppView from "../views/AppView.vue";
import VueRouter from "vue-router";
import AlumniRoutes from "./alumni";
import ProfileRoutes from "./profile";

import { getLocalStorageItem } from "@/utils/localStorage";

const routes = [
  {
    path: "/",
    name: "public-home",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/PublicHome.vue"),
    meta: { public: true },
  },
  {
    path: "/account/sign-up-confirmation",
    name: "account-sign-up-confirmation",
    component: () =>
      import(
        /* webpackChunkName: "AccountSignUpConfirmation" */ "../views/AccountSignUpConfirmation.vue"
      ),
    props: true,
    meta: { public: true },
  },
  {
    path: "/account/activate/:uid/:token",
    name: "account-activate",
    component: () =>
      import(
        /* webpackChunkName: "AccountActivate" */ "../views/AccountActivate.vue"
      ),
    props: true,
    meta: { public: true },
  },
  {
    path: "/account/password/forgotten",
    name: "account-forgotten-password",
    component: () =>
      import(
        /* webpackChunkName: "AccountPasswordForgotten" */ "../views/AccountPasswordForgotten.vue"
      ),
    props: true,
    meta: { public: true },
  },
  {
    path: "/account/password/new/:uid/:token/",
    name: "account-password-new",
    component: () =>
      import(
        /* webpackChunkName: "AccountPasswordNew" */ "../views/AccountPasswordNew.vue"
      ),
    props: true,
    meta: { public: true },
  },
  {
    path: "/account/password/reset/",
    name: "account-password-reset",
    component: () =>
      import(
        /* webpackChunkName: "AccountPasswordReset" */ "../views/AccountPasswordReset.vue"
      ),
    props: true,
    meta: { public: true },
  },
  {
    path: "/signout",
    name: "signout",
    component: () =>
      import(
        /* webpackChunkName: "ConfirmRegistration" */ "../views/SignOut.vue"
      ),
  },
  {
    path: "/app/",
    component: AppView,
    meta: { requiresAuth: true },
    children: [
      ...AlumniRoutes,
      ...ProfileRoutes,
      {
        path: "",
        name: "app-home",
        component: () =>
          import(/* webpackChunkName: "AppHome" */ "../views/AppViewHome.vue"),
      },
      {
        path: "news",
        name: "news",
        component: () =>
          import(/* webpackChunkName: "AppHome" */ "../views/News.vue"),
      },
      {
        path: "events",
        name: "events",
        component: () =>
          import(/* webpackChunkName: "AppHome" */ "../views/Events.vue"),
      },
      {
        path: "association",
        name: "about-association",
        component: () =>
          import(/* webpackChunkName: "AppHome" */ "../views/Association.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  const isLogged = getLocalStorageItem("access-token");
  if (to.meta.public || to.matched.some((record) => record.meta.public)) {
    if (isLogged) next({ name: "app-home" });
    else next();
  } else if (
    to.meta.requiresAuth ||
    to.matched.some((record) => record.meta.requiresAuth)
  ) {
    if (!isLogged) next({ name: "public-home" });
    else next();
  } else {
    next();
  }
});

export default router;
