import Vue from "vue";
import Vuex from "vuex";
import { login, register, getAccountData } from "../services/auth";
import { setBearerToken, deleteBearerToken } from "../api/intranet";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "@/utils/localStorage";

import AlumniModule from "./modules/alumni";
import EventsModule from "./modules/events";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAppLoaded: false,
    user: null,
    notification: null,
    accessToken: getLocalStorageItem("access-token"),
  },
  getters: {
    notification: (state) => state.notification,
    isLoggedIn: (state) => !!state.accessToken,
    user: (state) => state.user,
  },
  mutations: {
    ADD_NOTIFICATION(state, notification) {
      state.notification = notification;
    },
    REMOVE_NOTIFICATION(state) {
      state.notification = null;
    },
    SET_ACCESS_TOKEN(state, accessToken = null) {
      state.accessToken = accessToken;
    },
    SET_USER(state, user = null) {
      state.user = user;
    },
    SET_APP_IS_APP_LOADED(state, loaded) {
      state.isAppLoaded = loaded;
    },
  },
  actions: {
    async login({ commit, dispatch }, data) {
      const [response, error] = await login(data);
      const payload = [null, null];
      if (response) {
        payload[0] = response.data;
        commit("SET_ACCESS_TOKEN", response.data.access);
        setBearerToken(response.data.access);
        setLocalStorageItem("access-token", response.data.access);
        setLocalStorageItem("refresh-token", response.data.refresh);
        dispatch("initApp");
        commit("ADD_NOTIFICATION", {
          code: Vue.prototype.$code.SUCCESS_CODE.LOGGED_IN,
          type: Vue.prototype.$notification.type.SUCCESS,
        });
      } else {
        payload[1] = error;
        if (error.hasGlobalMessage) {
          commit("ADD_NOTIFICATION", {
            code: error.getGlobalMessage(),
            type: Vue.prototype.$notification.type.ERROR,
          });
        }
      }
      return Promise.resolve(payload);
    },
    async register({ commit }, data) {
      const [response, error] = await register(data);
      const payload = [null, null];
      if (!error) {
        commit("ADD_NOTIFICATION", {
          code: Vue.prototype.$code.SUCCESS_CODE.REGISTRATION,
          type: Vue.prototype.$notification.type.SUCCESS,
        });
        payload[0] = response;
      } else {
        payload[1] = error;
        if (error.hasGlobalMessage) {
          commit("ADD_NOTIFICATION", {
            code: error.getGlobalMessage(),
            type: Vue.prototype.$notification.type.ERROR,
          });
        }
      }
      return Promise.resolve(payload);
    },
    signOut({ commit }) {
      removeLocalStorageItem("access-token");
      removeLocalStorageItem("refresh-token");
      deleteBearerToken();
      commit("SET_USER");
      commit("SET_ACCESS_TOKEN");
      commit("ADD_NOTIFICATION", {
        code: Vue.prototype.$code.WARNING_CODE.SIGN_OUT,
        type: Vue.prototype.$notification.type.WARNING,
      });
    },
    removeNotification({ commit }) {
      commit("REMOVE_NOTIFICATION");
    },
    async getUser({ commit, dispatch }) {
      const [response] = await getAccountData();
      if (response) {
        commit("SET_USER", response.data);
        dispatch("alumni/getAccountAlumni");
      }
    },
    async initApp({ commit, dispatch }) {
      await Promise.all([
        dispatch("getUser"),
        dispatch("alumni/getAccountAlumni"),
        dispatch("alumni/getSkills"),
        dispatch("events/getEvents"),
      ]);
      commit("SET_APP_IS_APP_LOADED", true);
    },
    showNotification(
      { commit },
      notification = {
        code: Vue.prototype.$code.ERROR_CODE.UNKNOWN_ERROR,
        type: Vue.prototype.$notification.type.ERROR,
      }
    ) {
      commit("ADD_NOTIFICATION", notification);
    },
  },
  modules: {
    alumni: AlumniModule,
    events: EventsModule,
  },
});
