<template>
  <v-app>
    <template v-if="isLoggedIn">
      <app-navigation-drawer ref="appNavigationDrawer" />
      <app-navigation-bar
        @drawer:toogle="$refs.appNavigationDrawer.toogleDrawer()"
      />
    </template>
    <router-view />
    <app-notification-alert />
  </v-app>
</template>

<script>
import AppNavigationBar from "@/components/App/NavigationBar";
import AppNotificationAlert from "@/components/App/AppNotificationAlert";
import AppNavigationDrawer from "@/components/App/AppNavigationDrawer";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("app.meta.defaultPageTitle"),
      // all titles will be injected into this template
      titleTemplate: `%s | ${this.$t("app.meta.appTitle")}`,
    };
  },
  components: {
    AppNotificationAlert,
    AppNavigationBar,
    AppNavigationDrawer,
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  watch: {
    isLoggedIn: {
      handler(val) {
        if (val) this.initApp();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["initApp"]),
  },
};
</script>
<style lang="sass">
@import "styles/index"
</style>
